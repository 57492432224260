/**
 * External dependencies
 */
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * Internal dependencies
 */
import Layout from '../components/layout';
import SEO from '../components/seo';
import Section from '../components/section';
import Form from '../components/form';
import ShapedImage from '../components/shaped-image';
import ClientList from '../components/client-list';
import Blockquote from '../components/blockquote';
import Hero from '../components/hero';
import Button from '../components/button';
import ButtonGroup from '../components/button-group';

const WordPressSiteAuditPage = ( props ) => {
    const {
        location,
    } = props;

    return (
        <Layout
            location={location}
            ctaText="Order Now"
            ctaAnchor="#order"
            noSignUpForm
            hideHeader
            hideLinks
        >
            <SEO
                title="WordPress Site Audit"
                description="Get a business boosting WordPress Site Audit from trusted WordPress specialist Tom Hirst today."
                imageAlt="WordPress Site Audit"
            />
            <Hero
                imageName="tom-hirst-headshot.png"
                imageAlt="WordPress Site Auditor, Tom Hirst"
                imageCaption="WordPress Site Auditor, Tom Hirst"
                isCentered
                noHeader
                standardHeight
            >
                <h1>Need A WordPress Site Audit?</h1>
                <p className="lead">If your WordPress solution isn&apos;t up to scratch, you could be leaving money on the
                    table.
                </p>
                <p>A WordPress Site Audit from me will arm you with the knowledge to improve your website&apos;s performance and increase its business returns.</p>
                <p>Get the WordPress advice, support and peace of mind you need by ordering your WordPress Site Audit today.</p>
                <ButtonGroup>
                    <Button to="#order-now" icon="arrow-right" text="Order now" color="green" anchor />
                    <Button to="#keep-reading" icon="arrow-down" text="Keep reading" anchor />
                </ButtonGroup>
            </Hero>
            <Section id="keep-reading" narrow halfPadding>
                <h2>Does this sound familiar?</h2>
                <ul>
                    <li>You want to speed up your WordPress site and make it faster as
                        it takes too long to load.
                    </li>
                    <li>You&apos;d like to know how to improve your WordPress
                        site&apos;s on-site SEO.
                    </li>
                    <li>You have had issues with the security of your website.</li>
                    <li>The admin area of your WordPress site is difficult to use.</li>
                    <li>You have what feels like an excessive amount of plugins installed and
                        you don&apos;t even know what some do.
                    </li>
                    <li>The mobile experience of your site is putting customers off.</li>
                    <li>The codebase for your custom theme or plugin is
                        proving difficult to manage.
                    </li>
                    <li>Shipping new features is painful for your team.</li>
                </ul>
                <p>Then there&apos;s no doubt that your WordPress site could be better utilised by your business.</p>
                <p>A WordPress Site Audit from me will give you the map you need to get there.</p>
                <Button to="#order-now" icon="arrow-right" text="Order now" color="green" anchor />
            </Section>
            <Section halfPadding narrow color="light-grey">
                <h2>How it works</h2>
                <ul className="check-list numbers">
                    <li><span className="number-circle">1</span> Place your order using the form below.</li>
                    <li><span className="number-circle">2</span> I&apos;ll send you a detailed questionnaire focused on me getting
                        to know your business and your WordPress struggles.
                    </li>
                    <li><span className="number-circle">3</span> You&apos;ll send me the relevant access I need to properly audit
                        your website. Things like git repositories, FTP details, etc.
                    </li>
                    <li><span className="number-circle">4</span>
                        Using my decade&apos;s worth of expertise, I&apos;ll spend
                        time reviewing your WordPress site in detail. I&apos;ll
                        cover everything from performance, to security, to code
                        structure.
                    </li>
                    <li><span className="number-circle">5</span>
                        I&apos;ll compile my findings into a custom document full of clear,
                        actionable improvement points for you and your team.
                    </li>
                </ul>
                <Button to="#order-now" icon="arrow-right" text="Order now" color="green" anchor />
            </Section>
            <Section narrow halfPadding>
                <h2>WordPress Site Audit contents</h2>
                <p>My WordPress Site Audit contains (but not limited to, depending on
                    your specific use of WordPress) the following topics:
                </p>
                <h3>Initial Thoughts</h3>
                <p>First impressions count.</p>
                <p>While my WordPress Site Audit is primarily technical,
                    I&apos;ll advise on the overall design, layout and user experience of
                    your site off the bat.
                </p>
                <h3>Performance</h3>
                <p>The biggest source of frustration for a user is when your website
                    takes too long to load.
                </p>
                <p>I&apos;ll tell you how to improve performance from the easy wins,
                    to the more complex tasks.
                </p>
                <h3>Accessibility</h3>
                <p>Everyone should be able to access your website equally, regardless
                    of what hardware they&apos;re using or any impairment they have.
                </p>
                <p>
                    I&apos;ll compile a list of areas where your site&apos;s
                    accessibility can be better.
                </p>
                <h3>Code Structure</h3>
                <p>
                    I&apos;ll ask for access to your codebase so I can review
                    it&apos;s organisation.
                </p>
                <p>Your audit document will include enhancement suggestions for
                    stylesheets, HTML, PHP and JavaScript (where used).
                </p>
                <h3>Dependency management</h3>
                <p>Do you rely on 3rd party dependencies in your project? I&apos;ll show
                    you how to manage these more effectively.
                </p>
                <h3>Version Control</h3>
                <p>All software projects should be version controlled and a WordPress
                    project is no exception.
                </p>
                <p>If you already have a version control system in place, I&apos;ll
                    review this and advise on upgrades. If you aren&apos;t currently
                    version controlling, I&apos;ll set you up with a plan.
                </p>
                <h3>Deployment Strategy</h3>
                <p>How are you testing new features? How are you going live with them?
                    How do you roll back to a stable version when things go wrong?
                </p>
                <p>Going without a well thought out deployment strategy is a huge risk
                    when your business relies on your website. I&apos;ll tailor a deployment
                    strategy to suit your needs.
                </p>
                <h3>Security</h3>
                <p>WordPress sites get hacked. I&apos;ll show you how to protect yourself
                    with a security checklist for your project.
                </p>
                <h3>On-Site SEO</h3>
                <p>I&apos;ll teach you how to put in place best practice on-site SEO
                    when creating content for your WordPress site.
                </p>
                <h3>Future roadmap</h3>
                <p>
                    As well as a summary of the findings of my investigation, I&apos;ll
                    show you where to start by arranging these in order of priority.
                </p>
                <p>You&apos;ll get a list of actionable improvement items specific to your site.</p>
                <p>
                    I won&apos;t just include the shortcomings of your existing solution,
                    but a roadmap for iterative improvement moving forward.
                </p>
                <Button to="#order-now" icon="arrow-right" text="Order now" color="green" anchor />
            </Section>
            <Section id="order-now" color="green" halfPadding>
                <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <h2 className="no-margin">It&apos;s time to order your WordPress Site Audit</h2>
                        <p className="lead">Total investment: £4950 + VAT</p>
                        <p>Order now to gain the knowledge to:</p>
                        <ul className="icon-list">
                            <li>
                                <FontAwesomeIcon icon="check" />
                                Iron out any existing bugs.
                            </li>
                            <li>
                                <FontAwesomeIcon icon="check" />
                                Make your WordPress website faster.
                            </li>
                            <li>
                                <FontAwesomeIcon icon="check" />
                                Ensure your site is secure.
                            </li>
                            <li>
                                <FontAwesomeIcon icon="check" />
                                Reduce plugin and code bloat.
                            </li>
                            <li>
                                <FontAwesomeIcon icon="check" />
                                Structure your pages in the best way for SEO.
                            </li>
                            <li>
                                <FontAwesomeIcon icon="check" />
                                Adhere to accessibility best practices.
                            </li>
                            <li>
                                <FontAwesomeIcon icon="check" />
                                Manage your codebase more effectively.
                            </li>
                            <li>
                                <FontAwesomeIcon icon="check" />
                                Deploy changes and new features safely.
                            </li>
                            <li>
                                <FontAwesomeIcon icon="check" />
                                Plan strategically for the future of your WordPress project.
                            </li>
                        </ul>
                        <p>Stop leaving money on the table with a poorly optimised business website once and for all.</p>
                    </div>
                    <div className="col-12 col-lg-6">
                        <Form
                            id="contact-form"
                            contact
                            buttonAsideText="We can get started right away!"
                            messagePlaceholder="Hi Tom, I want to make my WordPress website better with a WordPress Site Audit."
                            buttonText="Order now"
                            pathname={location && location.pathname}
                        />
                    </div>
                </div>
                </div>
            </Section>
            <Section narrow color="light-grey" halfPadding>
                <h2>FAQs</h2>
                <p><strong>Who would benefit most from a WordPress Site Audit?</strong></p>
                <p>Anyone using WordPress for their web presence looking to make it the
                    best it can be.
                </p>
                <p>
                    <strong>
                        After I send payment, how quickly will the process start?
                    </strong>
                </p>
                <p>I&apos;ll send you an email with the questionnaire right away.</p>
                <p>As soon as you fill this in and send it back to me, along
                    with your site&apos;s access details, I can get to work.
                </p>
                <p><strong>Can I share the review document with my team and others?</strong></p>
                <p>Of course. Once I&apos;ve handed over your WordPress Site Audit document,
                    you&apos;re free to share it with whoever you like.
                </p>
                <p>
                    I&apos;ll never share any information from your
                    review without consent.
                </p>
                <p><strong>Can I hire you to action the findings of my audit?</strong></p>
                <p>I&apos;d be delighted to oversee the plan for improving your website through
                    to development depending on my availability at the time.
                </p>
                <p>You&apos;re not tied to using my development services when
                    purchasing a WordPress website audit.
                </p>
                <p>My review documents are structured clearly for any developer or agency
                    to be able to use.
                </p>
                <p>
                    <strong>I have a specific question about a WordPress Site
                        Audit, how can I get in touch?
                    </strong>
                </p>
                <p>Please <a href="mailto:hello@tomhirst.com">send me an email</a> with any queries
                    you have. I&apos;ll be happy to answer them.
                </p>
            </Section>
            <Section narrow halfPadding>
                <h2>About Tom Hirst</h2>
                <ShapedImage
                    name="tom-hirst-in-oslo.jpg"
                    alt="WordPress Development Consultant Tom Hirst in Oslo"
                    pullRight
                    flipped
                />
                <p>
                    I&apos;ve been in the technical WordPress space for over a
                    decade providing advice, support and peace of mind to individuals, start ups and businesses of all sizes.
                </p>
                <p>
                    In this time I&apos;ve been trusted to work on WordPress projects for the likes of the NHS,
                    Facebook, Umbro and TGI Fridays.
                </p>
                <p>
                    I&apos;ve helped hundreds of people improve their WordPress websites and their
                    businesses as a consequence.
                </p>
                <p>I can help you next.</p>
                <ClientList topRowOnly />
                <Blockquote
                    quote="Frankly speaking, you want people like Tom on your team."
                    cite="Brian Frolo, WordPress Design Agency Owner"
                    imageName="bf-headshot.jpg"
                    imageAlt="Brian Frolo"
                    largeQuote
                    boxed
                />
                <a className="button is-green has-icon" href="#order-now">
                    Order now
                    <FontAwesomeIcon icon="arrow-up" />
                </a>
            </Section>
        </Layout>
    );
};

WordPressSiteAuditPage.propTypes = {
    location: PropTypes.objectOf( PropTypes.any ),
};

WordPressSiteAuditPage.defaultProps = {
    location: {},
};

export default WordPressSiteAuditPage;
